import Drawer from '../drawer/Drawer';
import {
  DRAWERS,
  EVENT_BUS,
  EXCLUDED_URL_PARAMS,
  NESTED_URL_PARAMS,
  OPTIONAL_JOIN_KEYS,
} from '../common/constants';

class Controller {
  constructor({ cartOptions = null, cartReqParams } = {}) {
    this._addToCartListener = this._addToCartListener.bind(this);
    this._eventEmitterList = new Set();
    this._partsAndServicesListenerClose =
      this._partsAndServicesListenerClose.bind(this);
    this.cartOptions = cartOptions;
    this.cartReqParams = cartReqParams;
    this.drawer = null;
    this.hasUrlParams = true;
    this.messageParams = null;
    this.path = '';
    this.subPath = '';
  }

  /*******************************************
   *               Event Listeners           *
   *******************************************/
  _addToCartListener(event) {
    try {
      const { data } = event;
      if (data) {
        const { resp, type } = data;
        if (type === 'cart.atc-event') {
          window.removeEventListener('message', this._addToCartListener);
          if (this.drawer.isOpen) {
            this._trigger('success', resp);
          }
        } else if (
          type === 'cart.atc-pss-event' ||
          type === 'cart-pss-atc-event'
        ) {
          if (this.drawer.isOpen) {
            if (type === 'cart.atc-pss-event') {
              this.selectAStore(resp);
            } else if (type === 'cart-pss-atc-event') {
              this.addToCart({
                cartOptions: event.data.cartOptions,
                cartReqParams: event.data.cartModel,
              });
            }
          }
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  _partsAndServicesListenerClose(event) {
    if (event.data === 'PARTS_AND_SERVICES_CLOSED') {
      window.removeEventListener(
        'message',
        this._partsAndServicesListenerClose
      );
      window.top.postMessage({ action: 'close-drawer' }, '*');
    }
  }

  /*******************************************
   *             Private Methods             *
   *******************************************/
  _appendCacheBust() {
    this.urlParams.append(
      'bust',
      (Math.floor(Math.random() * 9000000000) + 1000000000).toString()
    );
  }

  _appendCartVersion() {
    const cookieName = 'experienceCartVersion';
    const version = document.cookie
      .match(`(^|;)\\s*${cookieName}\\s*=\\s*([^;]+)`)
      ?.pop();
    if (version) this.urlParams.append('expversion', version);
  }

  _appendUrlParams(params) {
    Object.entries(params).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        if (typeof value !== 'object' && !EXCLUDED_URL_PARAMS.includes(key)) {
          this.urlParams.append(key, value);
        } else if (NESTED_URL_PARAMS[key]) {
          this.urlParams.append(
            NESTED_URL_PARAMS[key].key,
            value[NESTED_URL_PARAMS[key].value]
          );
        }
      }
    });
  }

  _constructUrl() {
    if (this.path === 'parts-and-services') {
      const { itemIds, lineItemIds, pageType } = this.cartReqParams;
      this.subPath = `/${pageType}/${lineItemIds}/${itemIds}`;
    }
    const url = `${
      window.location.protocol + '//' + window.location.host
    }/cart/${this.path}${this.subPath}?${this._setUrlParameters().toString()}`;

    return url;
  }

  _getEventEmitterListByKey(key) {
    if (this._eventEmitterList.size > 0) {
      return Array.from(this._eventEmitterList).filter((namespace) => {
        const regex = new RegExp('^' + key + '.');
        return regex.test(namespace);
      });
    }
    return [];
  }

  _initializeDrawer({ shouldBeVisible = false } = {}) {
    if (!this.drawer) {
      this.drawer = new Drawer({
        hidden: shouldBeVisible ? false : this.cartOptions?.hidden,
        src: this._constructUrl(),
      });
    } else {
      this.drawer.hidden = shouldBeVisible ? false : this.cartOptions?.hidden,
      this.drawer.src = this._constructUrl();
    }
  }

  _isValidUrl(string) {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  }

  _setDefaultNamespace(key) {
    if (key.indexOf('.') === -1) {
      key = key + '.default';
    }
    return key;
  }

  _setDrawerPath(drawer) {
    const { hasUrlParams, path } = drawer;
    this.hasUrlParams = hasUrlParams;
    this.path = path;
  }

  _setUrlParameters() {
    this.urlParams = new URLSearchParams();
    this._appendCacheBust();

    if (this.hasUrlParams) {
      if (this.cartOptions) {
        this._appendUrlParams(this.cartOptions);
      }

      let cartReqParams = this.cartReqParams;

      if (Array.isArray(this.cartReqParams)) {
        const itemId = cartReqParams.map((item) => item?.itemId).join(',');
        cartReqParams = this.cartReqParams[0];
        cartReqParams.itemId = itemId;
      }

      OPTIONAL_JOIN_KEYS.forEach((key) => {
        if (Array.isArray(cartReqParams[key])) {
          cartReqParams[key] = cartReqParams[key].join(',');
        }
      });

      this._appendUrlParams(cartReqParams);
    }

    this._appendCartVersion();

    // ---TO REMOVE AFTER NEW GLOBAL-EVENTS-ORCHESTRATOR STABLE---
    this._appendUrlParams({ 'supercartEnabled': true });
    // -----------------------------------------------------------

    return this.urlParams;
  }

  _shouldRouteToCheckAvailabilty() {
    const APPLIANCE_FFM = 'DirectDelivery';
    if (!this.cartReqParams || this.cartOptions?.skipCheckAvailability)
      return false;
    if (Array.isArray(this.cartReqParams)) {
      return this.cartReqParams.every(
        (item) => item.fulfillmentMethod === APPLIANCE_FFM
      );
    } else {
      return this.cartReqParams.fulfillmentMethod === APPLIANCE_FFM;
    }
  }

  _trigger(key, data) {
    this._getEventEmitterListByKey(key).forEach((eventName) => {
      EVENT_BUS?.trigger(eventName, data);
    });
  }

  /*******************************************
   *             Event Bus Helpers           *
   *******************************************/
  off(key, fn) {
    const formattedKey = this._setDefaultNamespace(key);
    EVENT_BUS?.off(formattedKey, fn);
    this._eventEmitterList.delete(formattedKey);
    return this;
  }

  on(key, fn) {
    const formattedKey = this._setDefaultNamespace(key);
    EVENT_BUS?.on(formattedKey, (data) => fn(data.output));
    this._eventEmitterList.add(formattedKey);
    return this;
  }

  /*******************************************
   *             Public Methods              *
   *******************************************/
  addToCart({ cartOptions, cartReqParams } = {}) {
    this._setDrawerPath(DRAWERS.ADD_TO_CART);
    if (cartOptions && cartReqParams) {
      this.cartOptions = cartOptions;
      this.cartReqParams = cartReqParams;
    }
    if (this._shouldRouteToCheckAvailabilty()) {
      return this.applianceCheckAvailability();
    }
    this._initializeDrawer();
    this.drawer.open({
      cartOptions: this.cartOptions,
      cartReqParams: this.cartReqParams,
      type: 'cart.atc-add-item',
    });

    parent.addEventListener('message', this._addToCartListener);
    return this;
  }

  applianceCheckAvailability() {
    this._setDrawerPath(DRAWERS.CHECK_AVAILABILITY);
    this._initializeDrawer({ shouldBeVisible: true });
    this.drawer.open();
    parent.addEventListener('message', this._addToCartListener);
    return this;
  }

  instantCheckout() {
    this._setDrawerPath(DRAWERS.ADD_TO_CART);
    this._initializeDrawer();
    this.drawer.open({
      item: this.cartReqParams,
      type: 'cart.atc-instant-checkout',
    });
    return this;
  }

  partsAndServices() {
    this._setDrawerPath(DRAWERS.PARTS_AND_SERVICES);
    this._initializeDrawer();
    this.drawer.open();
    window.addEventListener('message', this._partsAndServicesListenerClose);
    return this;
  }

  protectionPlan() {
    this._setDrawerPath(DRAWERS.PROTECTION_PLAN);
    this._initializeDrawer();
    this.drawer.open();
    window.addEventListener('message', this._addToCartListener);
    return this;
  }

  selectAStore(cartReqParams) {
    this._setDrawerPath(DRAWERS.SELECT_A_STORE);
    if (cartReqParams) {
      this.drawer = null;
      this.cartReqParams = cartReqParams;
    }
    this._initializeDrawer();
    this.drawer.open();
    window.addEventListener('message', this._addToCartListener);
    return this;
  }
}

export default Controller;
